import { lazy } from 'react'
import locales from './locales'
import routes from './routes'
import themes from './themes'
import parseLanguages from 'base-shell/lib/utils/locale'
import grants from './grants'
import Loading from 'material-ui-shell/lib/components/Loading/Loading'
import getDefaultRoutes from './getDefaultRoutes'
import { defaultUserData, isGranted } from 'rmw-shell/lib/utils/auth'

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: 'AIzaSyDaNSTt5i0VCGHHWZqAH7WqY_1u0EcATQk',
        authDomain: 'dzemat-app-f08da.firebaseapp.com',
        databaseURL: 'https://dzemat-app-f08da.firebaseio.com',
        projectId: 'dzemat-app-f08da',
        storageBucket: 'dzemat-app-f08da.appspot.com',
        messagingSenderId: '662184876129',
        appId: '1:662184876129:web:b61123e09709c1e418f220',
      },
      messaging: {
        publicVapidKey: 'BEthk1-Qmoh9opZbi1AUZpxANTu6djVRDph4MLpyO2Qk6Dglm1Sa8Yt_pYi4EhYi3Tj-xgLqUktlbNuP_RP6gto',
      },
    },
    devp: {
      initConfig: {
        apiKey: 'AIzaSyDaNSTt5i0VCGHHWZqAH7WqY_1u0EcATQk',
        authDomain: 'dzemat-app-f08da.firebaseapp.com',
        databaseURL: 'https://dzemat-app-f08da.firebaseio.com',
        projectId: 'dzemat-app-f08da',
        storageBucket: 'dzemat-app-f08da.appspot.com',
        messagingSenderId: '662184876129',
        appId: '1:662184876129:web:b61123e09709c1e418f220',
      },
      messaging: {
        publicVapidKey: 'BEthk1-Qmoh9opZbi1AUZpxANTu6djVRDph4MLpyO2Qk6Dglm1Sa8Yt_pYi4EhYi3Tj-xgLqUktlbNuP_RP6gto',
      },
    },
    dev: {
      initConfig: {
        apiKey: 'AIzaSyDIkh6OV5ZxvpHoyhsGmX8tPM81ykAnMv0',
        authDomain: 'djemat-app.firebaseapp.com',
        databaseURL: 'https://djemat-app.firebaseio.com',
        projectId: 'djemat-app',
        storageBucket: 'djemat-app.appspot.com',
        messagingSenderId: '743209498025',
        appId: '1:743209498025:web:c97b5b9d231d3c07',
      },
      messaging: {
        publicVapidKey: 'BGddXH_O6qLmcingsSJx-R3hC8U9yUr2mW4ko63fF__e50WvfRcBfZu_JyBzLI35DNUE5x_9CPBqe64BWniCxV0',
      },
    },
    firebaseuiProps: {
      signInOptions: ['google.com', 'password'],
    },
  },
  googleMaps: {
    apiKey: 'AIzaSyByMSTTLt1Mf_4K1J9necAbw2NPDu2WD7g',
  },
  auth: {
    grants,
    redirectTo: '/dashboard',
    persistKey: 'base-shell:auth',
    signInURL: '/signin',
    onAuthStateChanged: async (user, auth, firebaseApp) => {
      if (user != null) {
        const grantsSnap = await firebaseApp.database().ref(`user_grants/${user.uid}`).once('value')

        const isAdminSnap = await firebaseApp.database().ref(`admins/${user.uid}`).once('value')

        firebaseApp
          .database()
          .ref(`user_grants/${user.uid}`)
          .on('value', (snap) => {
            auth.updateAuth({ grants: snap.val() })
          })

        firebaseApp
          .database()
          .ref(`admins/${user.uid}`)
          .on('value', (snap) => {
            auth.updateAuth({ isAdmin: !!snap.val() })
          })

        auth.updateAuth({
          ...defaultUserData(user),
          grants: grantsSnap.val(),
          isAdmin: !!isAdminSnap.val(),
          isGranted,
        })

        firebaseApp.database().ref(`users/${user.uid}`).update({
          displayName: user.displayName,
          uid: user.uid,
          photoURL: user.photoURL,
          providers: user.providerData,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
        })
      } else {
        firebaseApp.database().ref().off()
        auth.setAuth(defaultUserData(user))
      }
    },
  },
  getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: 'base-shell:locale',
    defaultLocale: parseLanguages(['en', 'de', 'bs'], 'bs'),
    onError: (e) => {
      //console.warn(e)

      return
    },
  },
  menu: {
    MenuContent: lazy(() => import('../components/Menu/MenuContent')),

    MenuHeader: lazy(() => import('material-ui-shell/lib/components/MenuHeader/MenuHeader')),
  },
  theme: {
    themes,
    defaultThemeID: 'default',
    defaultType: 'light',
  },
  pages: {
    LandingPage: lazy(() => import('../pages/LandingPage')),
    PageNotFound: lazy(() => import('../pages/PageNotFound')),
  },
  components: {
    Menu: lazy(() => import('rmw-shell/lib/containers/FirebaseMenu/FirebaseMenu')),
    Loading,
  },

  containers: {
    AppContainer: lazy(() => import('material-ui-shell/lib/containers/AppContainer/AppContainer')),
    LayoutContainer: lazy(() => import('rmw-shell/lib/containers/LayoutContainer/LayoutContainer')),
  },
}

export default config
