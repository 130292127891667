export const grants = [
  'create_company',
  'edit_company',
  'delete_company',
  'read_companies',
  'create_member',
  'edit_member',
  'delete_member',
  'read_members',
  'create_payment',
  'edit_payment',
  'delete_payment',
  'read_payments'
]

export default grants
