import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'

const Vaktija = lazy(() => import('../pages/Vaktija/Vaktija'))
const Membership = lazy(() => import('../pages/Membership'))
const MyData = lazy(() => import('../pages/MyData/MyData'))
const Members = lazy(() => import('../pages/Members/Members'))
const Member = lazy(() => import('../pages/Members/Member'))
const Payment = lazy(() => import('../pages/Payments/Payment'))
const Posts = lazy(() => import('../pages/Posts/Posts'))
const Post = lazy(() => import('../pages/Posts/Post'))
const View = lazy(() => import('../pages/Posts/View'))
const Managements = lazy(() => import('../pages/Managements'))
const Management = lazy(() => import('../pages/Managements/Management'))
const ManagementGroups = lazy(() => import('../pages/ManagementGroups'))
const ManagementGroup = lazy(() => import('../pages/ManagementGroups/ManagementGroup'))
const Events = lazy(() => import('../pages/Events'))

const routes = [
  <Route path="/" exact>
    <Redirect to="/dashboard" />
  </Route>,
  <Route path="/membership" exact component={Membership} />,
  <Route path="/vaktija" exact component={Vaktija} />,
  <AuthorizedRoute path="/dashboard" exact component={Posts} />,
  <AuthorizedRoute path="/my_data" exact component={MyData} />,
  <AuthorizedRoute path="/my_data/edit/:editType" exact component={MyData} />,
  <AuthorizedRoute path="/members" exact component={Members} />,
  <AuthorizedRoute path="/members/edit/:uid/:editType" exact component={Member} />,
  <AuthorizedRoute path="/create_member" exact component={Member} />,
  <AuthorizedRoute path="/payments/edit/:memberUid/:uid" exact component={Payment} />,
  <AuthorizedRoute path="/payments/:memberUid/create" exact component={Payment} />,
  <AuthorizedRoute path="/dashboard" exact component={Posts} />,
  <AuthorizedRoute path="/posts" exact component={Posts} />,
  <AuthorizedRoute path="/user_posts/edit/:uid" exact component={Post} />,
  <AuthorizedRoute path="/posts/view/:uid" exact component={View} />,
  <AuthorizedRoute path="/managements" exact component={Managements} />,
  <AuthorizedRoute path="/create_management" exact component={Management} />,
  <AuthorizedRoute path="/managements/:uid" exact component={Management} />,
  <AuthorizedRoute path="/management_groups" exact component={ManagementGroups} />,
  <AuthorizedRoute path="/create_management_group" exact component={ManagementGroup} />,
  <AuthorizedRoute path="/management_groups/:uid" exact component={ManagementGroup} />,
  <AuthorizedRoute path="/events" exact component={Events} />,
]

export default routes
